import React from "react"
import styled from "@emotion/styled"
import { formatDate } from "../../../helpers/format-date"

const TitleContract = styled.div`
  line-height: 16px;
  font-weight: bold;
  margin-right: 10px;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
`

const DetailContract = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    border-bottom: 1px solid #efefef;
    flex-direction: column;
    align-items: initial;
    padding: 10px 0 10px 5px;
  }
`
const TitleDetailContract = styled.div`
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 5px;
  color: #939393;
  @media (max-width: 1024px) {
    font-weight: bold;
  }
`
const DataContract = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const LastTradingDay = styled.div`
  text-align: center;
  margin: 0 10px;
  @media (max-width: 1024px) {
    text-align: initial;
    margin: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 5px 0;
  }
`
const ExpirationDay = styled(LastTradingDay)``
const ValueContract = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  @media (max-width: 1024px) {
    font-weight: initial;
  }
`

type Props = {
  instrument: Instrument
  Divisor: any
}

const Contract = ({
  instrument: { last_tranding_day, expiration_day },
  Divisor,
}: Props) => {
  const lastTrandingDay = formatDate(last_tranding_day)
  const expirationDay = formatDate(expiration_day)

  return (
    <>
      {lastTrandingDay && expirationDay && (
        <>
          <Divisor></Divisor>
          <DetailContract>
            <TitleContract>Detalle Contratos</TitleContract>
            <DataContract>
              <LastTradingDay>
                <ValueContract>{lastTrandingDay}</ValueContract>
                <TitleDetailContract>Last Trading Day</TitleDetailContract>
              </LastTradingDay>
              <ExpirationDay>
                <ValueContract>{expirationDay}</ValueContract>
                <TitleDetailContract>
                  Expiración de contrato
                </TitleDetailContract>
              </ExpirationDay>
            </DataContract>
          </DetailContract>
        </>
      )}
    </>
  )
}

export default Contract
