import React from "react"

const ShowMore = () => (
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.76875 -5.78356e-07L7.5 5.56507L13.2313 -7.73145e-08L15 1.71746L7.5 9L-7.50728e-08 1.71746L1.76875 -5.78356e-07Z"
      fill="#72C552"
    />
  </svg>
)

export default ShowMore
