import React from "react"
import { Modal } from "react-responsive-modal"
import { SerializedStyles } from "@emotion/core"
import styled from "@emotion/styled"

import "react-responsive-modal/styles.css"

import X from "./images/x-icon"

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-weight: 600;
  border-radius: 2px;
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  color: #ffffff;
  padding: 1rem;
`

type Props = {
  title: string
  children: any
  isOpen: boolean
  styleCSS?: SerializedStyles
  closeModal: () => void
}

const CustomModal = ({
  title,
  children,
  isOpen,
  closeModal,
  styleCSS,
}: Props) => (
  <Modal
    open={isOpen}
    onClose={closeModal}
    classNames={{
      modal: "custom-modal",
    }}
    closeIcon={<X />}
  >
    <Header css={styleCSS}>{title}</Header>
    {children}
  </Modal>
)

export default CustomModal
