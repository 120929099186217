/** @jsx jsx */
import { useState } from "react"
import { jsx, SerializedStyles } from "@emotion/core"
import styled from "@emotion/styled"

import GreenArrowIcon from "../ui/images/green-arrow-icon"

const Container = styled.div`
  border-right: 1px solid #e0e0e0;
  background-color: #ffffff;
  width: 100%;
  position: relative;
`

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1f2425;
  text-decoration: none;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 1.375rem;
  min-height: 54px;
  cursor: pointer;
`

const ToggleButtonText = styled.span`
  flex-grow: 1;
`

const Tabs = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 54px;
  width: 100%;
  background-color: #ffffff;
`

const Tab = styled.li`
  border-bottom: 1px solid #e0e0e0;
  padding: 0.75rem 1.375rem;
  color: #a0a0a0;
  cursor: pointer;
`

type Props = {
  tabs: TabType[]
  selectedTab: TabType
  onClick: (tab: TabType) => void
  containerCSS?: SerializedStyles
}

const DropdownTabs = ({ tabs, selectedTab, onClick, containerCSS }: Props) => {
  const [isOpened, setIsOpened] = useState(false)
  const filteredTabs: TabType[] = tabs.filter(
    t => t.value !== selectedTab.value
  )

  return (
    <Container css={containerCSS} className={isOpened ? "open" : ""}>
      <ToggleButton onClick={() => setIsOpened(!isOpened)}>
        <ToggleButtonText>{selectedTab.text}</ToggleButtonText>
        <GreenArrowIcon />
      </ToggleButton>

      {isOpened && (
        <Tabs>
          {filteredTabs.map((tab, idx) => (
            <Tab
              className={tab.className}
              key={`dropdown-tab-${idx}`}
              onClick={() => {
                setIsOpened(false)
                onClick(tab)
              }}
            >
              {tab.text}
            </Tab>
          ))}
        </Tabs>
      )}
    </Container>
  )
}

export default DropdownTabs
