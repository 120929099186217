import moment from "moment"

const formatDate = (date: string | undefined = "") => {
  let returnDateFormatted
  const dateFormat = moment(date, "YYYY-MM-DD", true)

  if (date !== undefined && dateFormat.isValid()) {
    returnDateFormatted = dateFormat.format("DD-MM-YYYY")
  } else {
    returnDateFormatted = date
  }

  return returnDateFormatted
}

export { formatDate }
