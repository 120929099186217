import React from "react"

const SearchIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8645 11.3208H12.0463L11.7633 11.0377C12.7719 9.86964 13.3791 8.35163 13.3791 6.68954C13.3791 2.99485 10.3842 0 6.68954 0C2.99485 0 0 2.99485 0 6.68954C0 10.3842 2.99485 13.3791 6.68954 13.3791C8.35163 13.3791 9.86964 12.7719 11.0377 11.7684L11.3208 12.0515V12.8645L16.4666 18L18 16.4666L12.8645 11.3208ZM6.68954 11.3208C4.13208 11.3208 2.05832 9.247 2.05832 6.68954C2.05832 4.13208 4.13208 2.05832 6.68954 2.05832C9.247 2.05832 11.3208 4.13208 11.3208 6.68954C11.3208 9.247 9.247 11.3208 6.68954 11.3208Z"
      fill="white"
    />
  </svg>
)

export default SearchIcon
