import React from "react"

import Layout from "../components/ui/layout"
import SEO from "../components/seo"
import OperationalAspects from "../components/operational-aspects-page/operational-aspects-page"

const AspectosOperativos = () => (
  <Layout>
    <SEO title="Mercados y Aspectos Operativos" />
    <OperationalAspects />
  </Layout>
)

export default AspectosOperativos
