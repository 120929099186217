export const getInstruments = (
  categories: Categories,
  selectedCategory: string,
  search: any,
  sort: { column: string; desc: boolean }
) => {
  let instruments: Instrument[] = []
  if (
    (selectedCategory === "all" ||
      categories[selectedCategory] === undefined) &&
    search === null
  ) {
    instruments = Object.values(categories).reduce(
      (allInstruments: Instrument[], category: Category) =>
        allInstruments.concat(category.instruments),
      []
    )
  } else if (search === null) {
    instruments = categories[selectedCategory].instruments
  } else if (categories[search.category]) {
    instruments = categories[search.category].instruments.filter(
      (instrument: Instrument) => instrument.nemo === search.value
    )
  }

  if (instruments.length) {
    instruments = instruments.sort((a: Instrument, b: Instrument) => {
      const [first, second] = sort.desc ? [a, b] : [b, a]
      if (first[sort.column] > second[sort.column]) {
        return 1
      } else if (first[sort.column] < second[sort.column]) {
        return -1
      } else {
        return 0
      }
    })
  }
  return instruments
}
