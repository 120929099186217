import React, { useState } from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"

import TypeAccountDetail from "./type-account-detail"
import PageLayout from "../ui/page-layout"
import GreenTabs from "../ui/green-tabs"
import { getMarkets } from "../../helpers/markets"
import { css } from "@emotion/core"
import { trackEvent } from "../../helpers/tracker"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding-bottom: 5rem;
  background: #f4f4f4;
`

const Description = styled.p`
  font-family: Nunito;
  font-size: 18px;
  line-height: 120%;
  color: #1f2425;
  text-align: center;
  padding: 5rem 0 0;
  margin: 0;
  display: none;
  @media (min-width: 1024px) {
    max-width: 533px;
    display: block;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    max-width: 533px;
    display: block;
  }
`

const TextOnlyDesktop = styled.span`
  @media (max-width: 740px) {
    display: none;
  }
`

const TabCSS = css`
  padding: 12px 0 10px;
  min-width: 220px;
  @media (max-width: 740px) {
    min-width: 117px;
    padding: 12px 0 3px;
  }
`

const tabs: TabType[] = [
  {
    text: (
      <>
        <TextOnlyDesktop>Cuenta</TextOnlyDesktop> Start
      </>
    ),
    value: "start",
    market: "start",
    details: {
      name: "Start",
      instruments: "hasta 16",
      market: "5",
      leverage: "4",
    },
  },
  {
    text: (
      <>
        <TextOnlyDesktop>Cuenta</TextOnlyDesktop> Pro
      </>
    ),
    value: "pro",
    market: "pro",
    details: {
      name: "Pro",
      instruments: "más de 400",
      market: "6",
      leverage: "100",
    },
  },
  {
    text: (
      <>
        <TextOnlyDesktop>Cuenta</TextOnlyDesktop> Premium
      </>
    ),
    value: "premium",
    market: "pro",
    details: {
      name: "Premium",
      instruments: "más de 400",
      market: "6",
      leverage: "100",
    },
  },
]

const nameMarkets: IMarkets = {
  forex: "Divisas",
  stocks: "Acciones",
  adr: "ADRs",
  commodities: "Commodities",
  index: "Índices",
  etf: "ETFs",
}

const OperationalAspectsPage = () => {
  const markets = useStaticQuery(graphql`
    {
      prismicForexPro {
        ...ForexPro
      }
      prismicForexStart {
        ...ForexStart
      }
      prismicCryptoPro {
        ...CryptoPro
      }
      prismicCryptoStart {
        ...CryptoStart
      }
      prismicStocksPro {
        ...StocksPro
      }
      prismicStocksEeuuPro {
        ...StocksEeuuPro
      }
      prismicStocksEuropaPro {
        ...StocksEuropaPro
      }
      prismicStocksCanadaPro {
        ...StocksCanadaPro
      }
      prismicStocksAsiaPro {
        ...StocksAsiaPro
      }
      prismicStocksStart {
        ...StocksStart
      }
      prismicAdrPro {
        ...AdrPro
      }
      prismicCommoditiesPro {
        ...CommoditiesPro
      }
      prismicCommoditiesStart {
        ...CommoditiesStart
      }
      prismicIndexPro {
        ...IndexPro
      }
      prismicIndexStart {
        ...IndexStart
      }
      prismicEtfPro {
        ...EtfPro
      }
      prismicEtfStart {
        ...EtfStart
      }
    }
  `)

  const [selectedTypeAccount, setSelectedTypeAccount] = useState(tabs[1])
  const dataMarkets = getMarkets(markets, selectedTypeAccount.market || "pro")

  const marketsEnabled: TabType[] = Object.keys(dataMarkets).map(
    (dataMarket: string) => ({
      value: dataMarket,
      text:
        nameMarkets[dataMarket.toLocaleLowerCase()] ||
        dataMarket.toLocaleLowerCase(),
      className: "",
    })
  )

  const onSelectedTypeAccount = (selectedTypeAccount: TabType) => {
    setSelectedTypeAccount(selectedTypeAccount)
    trackEvent(`aspectos-operativos-kt:tab:${selectedTypeAccount.value}`)
  }

  return (
    <PageLayout title="Mercados y Aspectos Operativos">
      <Container>
        <Description>Selecciona un tipo de cuenta</Description>

        <GreenTabs
          tabs={tabs}
          selectedTab={selectedTypeAccount}
          onClick={onSelectedTypeAccount}
          tabCSS={TabCSS}
        />
        <TypeAccountDetail
          dataMarkets={dataMarkets}
          marketsEnabled={marketsEnabled}
          selectedTypeAccount={selectedTypeAccount}
        />
      </Container>
    </PageLayout>
  )
}

export default OperationalAspectsPage
