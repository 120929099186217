import React from "react"

const QuestionIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0C2.685 0 0 2.685 0 6C0 9.315 2.685 12 6 12C9.315 12 12 9.315 12 6C12 2.685 9.315 0 6 0ZM6.6 10.2H5.4V9H6.6V10.2ZM7.839 5.553L7.302 6.105C6.87 6.537 6.6 6.9 6.6 7.8H5.4V7.5C5.4 6.837 5.67 6.237 6.102 5.802L6.849 5.046C7.065 4.83 7.2 4.53 7.2 4.2C7.2 3.537 6.663 3 6 3C5.337 3 4.8 3.537 4.8 4.2H3.6C3.6 2.874 4.674 1.8 6 1.8C7.326 1.8 8.4 2.874 8.4 4.2C8.4 4.728 8.187 5.205 7.839 5.553Z"
      fill="#72C552"
    />
  </svg>
)

export default QuestionIcon
