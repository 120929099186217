import React, { useState } from "react"
import styled from "@emotion/styled"
import ChileFlag from "../ui/images/chile"
import PeruFlag from "../ui/images/peru"
import UruguayFlag from "../ui/images/uruguay"

const ContainerSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  min-width: 255px;
  @media (max-width: 460px) {
    margin-top: 14px;
    justify-content: center;
  }
`

const TitleSelector = styled.div`
  font-size: 12px;
  line-height: 16px;
`

const ContainerFlag = styled.div`
  display: flex;
`

const Flag = styled.div`
  padding: 4px 6px;
  border: 1px solid transparent;
  height: 29px;
  border-radius: 5px;
  margin: 0 6.5px;
  cursor: pointer;
  &.selected {
    border: 1px solid #30ab76;
  }
`

type Props = {
  setCountry: (country: string) => void
}

const SelectCountry = ({ setCountry }: Props) => {
  const [selectedFlag, setSelectedFlag] = useState("chile")

  const countries = [
    {
      name: "chile",
      flag: ChileFlag,
    },
    {
      name: "peru",
      flag: PeruFlag,
    },
    {
      name: "uruguay",
      flag: UruguayFlag,
    },
  ]

  return (
    <ContainerSelect>
      <TitleSelector>Mostrar horarios de</TitleSelector>
      <ContainerFlag>
        {countries.map(country => (
          <Flag
            className={selectedFlag === country.name ? "selected" : ""}
            onClick={() => {
              setSelectedFlag(country.name)
              setCountry(country.name)
            }}
            key={`idcountry-${country.name}`}
          >
            <country.flag />
          </Flag>
        ))}
      </ContainerFlag>
    </ContainerSelect>
  )
}

export default SelectCountry
