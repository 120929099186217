import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import CloseSearchIcon from "../ui/images/close-search-icon"
import DropdownTabs from "../ui/dropdown-tabs"
import InstrumentsTable from "./operational-aspects-table"
import SearchComponent from "./search"
import Tabs from "./tabs"
import ExtraInformation from "./extra-information"
import { trackEvent } from "../../helpers/tracker"
import SelectCountry from "./select-country"

const ContainerTypeAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
`

const SearchSelected = styled.div`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-left: 10px;
  color: #ffffff;
  cursor: pointer;
`

const ContainerSearchSelected = styled.div`
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 16px;
  @media (max-width: 1024px) {
    padding-left: 16px;
  }
`

const TextSearchSelected = styled.div`
  padding-right: 10px;
`

const dropdownTabsContainerCSS = css`
  display: none;
  background: #16191a;
  border: none;
  min-height: 60px;
  z-index: 1;
  @media (max-width: 768px) {
    display: block;
  }

  > div {
    border-bottom-color: transparent;
    min-height: 60px;
  }
  &.open {
    > div {
      border-bottom-color: initial;
    }
  }

  span {
    color: white;
  }
  ul {
    background: #000000;
    top: 60px;
  }
  li {
    color: rgba(245, 245, 245, 0.7);
    border-bottom: 0.5px solid #272727;
  }
`

const ContainerTable = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    padding: 0 20px;
  }
  @media (max-width: 460px) {
    padding: 0;
  }
`

const DescriptionAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 770px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 45px;
`

const TitleAccount = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 30px;
`

const DetailAcount = styled.div`
  font-size: 16px;
  line-height: 27px;
  @media (max-width: 500px) {
    font-size: 16px;
    padding: 0 20px;
  }
`

const ContainerSearchCountry = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 51px;
  @media (max-width: 560px) {
    flex-direction: column;
  }
`

const TypeAccountDetail = ({
  dataMarkets,
  marketsEnabled,
  selectedTypeAccount,
}: any) => {
  const [search, setSearch] = useState<OptionSelect | null>(null)
  const [country, setCountry] = useState("chile")
  const [marketSelected, setMarketSelected] = useState<TabType>(
    marketsEnabled[0]
  )
  useEffect(() => {
    setMarketSelected(marketsEnabled[0])
    setSearch(null)
  }, [selectedTypeAccount])

  const options: OptionSelect[] = Object.values(dataMarkets).reduce(
    (allInstruments: OptionSelect[], category: any) => [
      ...allInstruments,
      ...category.allInstruments,
    ],
    []
  ) as OptionSelect[]

  const selectedMarketValue =
    search !== null ? search.market : marketSelected.value

  const setSelectedCountry = (selectedCountry: string) => {
    setCountry(selectedCountry)
    trackEvent(`aspectos-operativos-kt:select-country`, {
      country: selectedCountry,
    })
  }

  const onSelectMarket = (selectedTab: any) => {
    setMarketSelected(selectedTab)
    setSearch(null)
    trackEvent(`aspectos-operativos-kt:market`, {
      typeAccount: selectedTypeAccount.value,
      market: marketSelected.value,
    })
  }

  const setSearchElement = (option: OptionSelect) => {
    setSearch(option)
    trackEvent(`aspectos-operativos-kt:search`, {
      nemo: option.value,
    })
  }

  return (
    <ContainerTypeAccount>
      <DescriptionAccount>
        <TitleAccount>Mercados disponibles</TitleAccount>
        <DetailAcount>
          Con tu <strong>Cuenta {selectedTypeAccount.details.name}</strong>{" "}
          podrás operar{" "}
          <strong>
            {selectedTypeAccount.details.instruments} instrumentos
          </strong>{" "}
          en{" "}
          <strong>
            {selectedTypeAccount.details.market} mercados diferentes
          </strong>{" "}
          con apalancamiento máximo de{" "}
          <strong>
            {selectedTypeAccount.details.leverage} veces tu capital.
          </strong>
        </DetailAcount>
      </DescriptionAccount>
      <SearchComponent setSearch={setSearchElement} options={options} />
      <ContainerSearchCountry>
        <ContainerSearchSelected>
          {search !== null && (
            <>
              <div>Resultado: </div>
              <SearchSelected onClick={() => setSearch(null)}>
                <TextSearchSelected>{search.value}</TextSearchSelected>
                <CloseSearchIcon />
              </SearchSelected>
            </>
          )}
        </ContainerSearchSelected>
        <SelectCountry setCountry={setSelectedCountry} />
      </ContainerSearchCountry>
      <ContainerTable>
        {/*
         * Revisar este componente de Tabs y DropdownTabs
         * y refactorizar en un solo componente
         */}
        <Tabs
          tabs={marketsEnabled}
          selectedValue={selectedMarketValue}
          search={search}
          onSelected={onSelectMarket}
        />
        <DropdownTabs
          tabs={marketsEnabled}
          selectedTab={marketSelected}
          onClick={onSelectMarket}
          containerCSS={dropdownTabsContainerCSS}
        />
        {dataMarkets[selectedMarketValue] !== undefined && (
          <InstrumentsTable
            selectedMarketValue={selectedMarketValue}
            data={dataMarkets[selectedMarketValue]}
            typeAccount={selectedTypeAccount.value}
            search={search}
            country={country}
          />
        )}
      </ContainerTable>
      <ExtraInformation typeAccount={selectedTypeAccount.value} />
    </ContainerTypeAccount>
  )
}

export default TypeAccountDetail
