import React from "react"

const UruguayFlag = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 15.0004C0 16.2277 0.995 17.2227 2.22222 17.2227H17.7778C19.005 17.2227 20 16.2277 20 15.0004V14.4449H0V15.0004Z"
      fill="#0038A8"
    />
    <path d="M0 12.7773H20V10.5551H0V12.7773Z" fill="#0038A8" />
    <path d="M0 14.4453H20V12.7786H0V14.4453Z" fill="#EEEEEE" />
    <path
      d="M19.9998 5C19.9998 3.77278 19.0048 2.77778 17.7776 2.77778H8.88867V5H19.9998Z"
      fill="#0038A8"
    />
    <path
      d="M8.88867 8.88867H19.9998V6.66645H8.88867V8.88867Z"
      fill="#0038A8"
    />
    <path
      d="M8.88889 5.00108V2.77886H8.61111H2.22222C0.995 2.77886 0 3.77386 0 5.00108V10.14V10.5566H8.88889H20V8.88997H8.88889V6.66775H20V5.00108H8.88889Z"
      fill="#EEEEEE"
    />
    <path
      d="M7.22213 6.66754C7.22213 5.13309 5.97879 3.88976 4.44435 3.88976C2.9099 3.88976 1.66602 5.13309 1.66602 6.66754C1.66602 8.20142 2.9099 9.44531 4.44435 9.44531C5.97879 9.44531 7.22213 8.20142 7.22213 6.66754"
      fill="#FFD983"
    />
    <path
      d="M6.66766 6.66645C6.66766 5.43923 5.67321 4.44423 4.44543 4.44423C3.21766 4.44423 2.22266 5.43923 2.22266 6.66645C2.22266 7.89312 3.21766 8.88867 4.44543 8.88867C5.67321 8.88867 6.66766 7.89312 6.66766 6.66645"
      fill="#FFCC4D"
    />
    <path
      d="M4.44394 3.10961L4.79561 5.35461L6.22283 3.58628L5.4045 5.70628L7.5245 4.8885L5.75616 6.31572L8.00116 6.66683L5.75616 7.0185L7.5245 8.44572L5.4045 7.62794L6.22283 9.74794L4.79561 7.97961L4.44394 10.2246L4.09227 7.97961L2.66505 9.74794L3.48339 7.62794L1.36339 8.44572L3.13172 7.0185L0.886719 6.66683L3.13172 6.31572L1.36339 4.8885L3.48339 5.70628L2.66505 3.58628L4.09227 5.35461L4.44394 3.10961Z"
      fill="#FFAC33"
    />
    <path
      d="M6.11123 6.66732C6.11123 5.74676 5.36512 5.00065 4.44457 5.00065C3.52345 5.00065 2.77734 5.74676 2.77734 6.66732C2.77734 7.58732 3.52345 8.33398 4.44457 8.33398C5.36512 8.33398 6.11123 7.58732 6.11123 6.66732"
      fill="#C1694F"
    />
    <path
      d="M3.24023 6.66968C3.24023 6.00857 3.77579 5.47301 4.43634 5.47301C5.09746 5.47301 5.63301 6.00857 5.63301 6.66968C5.63301 7.32968 5.09746 7.86523 4.43634 7.86523C3.77579 7.86523 3.24023 7.32968 3.24023 6.66968Z"
      fill="#FFAC33"
    />
  </svg>
)

export default UruguayFlag
