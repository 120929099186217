import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import CustomModal from "../../ui/custom-modal"

const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 23px 50px 50px;
  max-width: 500px;
`

const Content = styled.div`
  width: 100%;
`

const TitleModal = css`
  background: #000000;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #ffffff;
`

const Paragraph = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-size: 11.11px;
  line-height: 140%;
  margin: 0 0 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

type PropsChildren = {
  children: any
}

const ModalChildren = ({ children }: PropsChildren) => {
  return (
    <ContainerModal>
      <Content>{children}</Content>
    </ContainerModal>
  )
}

type Props = {
  title: string
  isModalOpen: boolean
  children: any
  closeModal: () => void
}

const Modal = ({ title, isModalOpen, closeModal, children }: Props) => {
  return (
    <CustomModal
      title={title}
      isOpen={isModalOpen}
      closeModal={closeModal}
      styleCSS={TitleModal}
    >
      <ModalChildren>{children}</ModalChildren>
    </CustomModal>
  )
}

export { Modal, Paragraph }
