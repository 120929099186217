import React from "react"
import styled from "@emotion/styled"

const TitleShortPosition = styled.div`
  line-height: 16px;
  font-weight: bold;
  margin-right: 10px;
`

const ValueShortPosition = styled.div``

const ShortPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    margin-right: 0px;
  }
  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2px;
    padding: 10px 0 0;
    > div {
      margin-left: 5px;
    }
  }
`

type Props = {
  instrument: Instrument
  Divisor: any
}
const ShortPosition = ({ instrument, Divisor }: Props) => (
  <>
    <Divisor></Divisor>
    <ShortPositionContainer>
      <TitleShortPosition>Posición Corta</TitleShortPosition>
      <ValueShortPosition>
        {instrument.short_position ? "Permitida" : "No permitida"}
      </ValueShortPosition>
    </ShortPositionContainer>
  </>
)

export default ShortPosition
