import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const TabsContainer = styled.div`
  display: flex;
  max-width: 1120px;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`

const Tab = styled.div`
  width: 100%;
  color: rgba(245, 245, 245, 0.7);
  padding-top: 23px;
  padding-bottom: 23px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  font-family: "Open Sans";
  background: #16191a;
  cursor: pointer;
  transition: color 1s;
  &:hover {
    color: #ffffff;
    transition: 0.3s;
  }
  &.selected {
    background: #000000;
    font-weight: bold;
    color: #ffffff;
  }
`

type Tab = {
  name?: string
  value: string
  text?: string
  className?: string
}

type PropsTypes = {
  tabs: Tab[]
  selectedValue: string
  search?: any
  // @ts-ignore
  onSelected: Dispatch<SetStateAction<any | null>>
  styleCss?: any
  styleTab?: any
  styleSelectedTab?: any
}

const Tabs = ({
  tabs,
  selectedValue,
  search,
  onSelected,
  styleCss,
  styleTab,
  styleSelectedTab,
}: PropsTypes) => (
  <TabsContainer css={styleCss}>
    {tabs.map(tab => {
      const isSelected = tab.value === selectedValue && search === null
      const styleTabCSS = isSelected ? [styleTab, styleSelectedTab] : [styleTab]

      return (
        <Tab
          className={isSelected ? "selected" : ""}
          css={styleTabCSS}
          key={`inst-${tab.value}`}
          onClick={() => onSelected(tab)}
        >
          {tab.name || tab.text}
        </Tab>
      )
    })}
  </TabsContainer>
)

export default Tabs
