/** @jsx jsx */
import { jsx, SerializedStyles } from "@emotion/core"
import styled from "@emotion/styled"

const Container = styled.div``

const Tabs = styled.div`
  display: flex;
  margin-top: 2.5rem;
`

const Tab = styled.button`
  flex-grow: 1;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  color: #a0a0a0;
  background: transparent;
  padding: 12px 64px;
  text-transform: none;
  cursor: pointer;
  border: 0;
  border-bottom: 2px solid #e0e0e0;
  min-width: 217px;
  &:focus {
    outline: 0;
  }
`

const activeTab = {
  borderBottom: "4px solid #30ab76",
  color: "#000000",
}

type Props = {
  tabs: TabType[]
  selectedTab: TabType
  onClick: (tab: TabType) => void
  containerCSS?: SerializedStyles
  tabCSS?: SerializedStyles
}

const GreenTabs = ({
  tabs,
  selectedTab,
  onClick,
  containerCSS,
  tabCSS,
}: Props) => (
  <Container css={containerCSS}>
    <Tabs>
      {tabs.map((tab, idx) => (
        <Tab
          css={tabCSS}
          key={`green-tab-${idx}`}
          style={selectedTab.value === tab.value ? activeTab : undefined}
          onClick={() => onClick(tab)}
        >
          {tab.text}
        </Tab>
      ))}
    </Tabs>
  </Container>
)

export default GreenTabs
