import React from "react"
import styled from "@emotion/styled"
import { renderWithBreakLine } from "../../../helpers/break-line-from-text"
import { changesHours } from "../../../helpers/change-hours"

const ScheduleRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    border-bottom: 1px solid #efefef;
    justify-content: space-between;
    padding: 20px 0 20px 5px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
  }
`

const TitleSchedule = styled.div`
  line-height: 16px;
  font-weight: bold;
  margin-right: 10px;
  @media (max-width: 700px) {
    margin: 0px 0 15px;
  }
`

const DataSchedule = styled.div`
  display: flex;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    max-width: 231px;
    justify-content: center;
  }
`

const DaySchedule = styled.div`
  min-width: 75px;
  min-height: 50px;
  border: 1px solid #cedef1;
  box-sizing: border-box;
  margin: 0 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  line-height: 14px;
  padding: 6px 0;
  @media (max-width: 700px) {
    margin-top: 5px;
  }
`

const DayTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
`

type Props = {
  instrument: Instrument
  country: string
}

const Schedule = ({ instrument, country }: Props) => {
  const renderHours = (hours: string) => {
    return changesHours(
      hours,
      ", ",
      <br
        key={Math.random()
          .toString(36)
          .substring(7)}
      />,
      country
    )
  }

  return (
    <ScheduleRow>
      <TitleSchedule>Horario Transacción: </TitleSchedule>
      <DataSchedule>
        {instrument.sunday && (
          <DaySchedule>
            <DayTitle>Dom</DayTitle>
            {renderHours(instrument.sunday)}
          </DaySchedule>
        )}
        {instrument.monday && (
          <DaySchedule>
            <DayTitle>Lun</DayTitle>
            {renderHours(instrument.monday)}
          </DaySchedule>
        )}
        {instrument.tuesday && (
          <DaySchedule>
            <DayTitle>Mar</DayTitle>
            {renderHours(instrument.tuesday)}
          </DaySchedule>
        )}
        {instrument.wednesday && (
          <DaySchedule>
            <DayTitle>Mier</DayTitle>
            {renderHours(instrument.wednesday)}
          </DaySchedule>
        )}
        {instrument.thursday && (
          <DaySchedule>
            <DayTitle>Jue</DayTitle>
            {renderHours(instrument.thursday)}
          </DaySchedule>
        )}
        {instrument.friday && (
          <DaySchedule>
            <DayTitle>Vie</DayTitle>
            {renderHours(instrument.friday)}
          </DaySchedule>
        )}
      </DataSchedule>
    </ScheduleRow>
  )
}

export default Schedule
