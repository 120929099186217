import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  display: flex;
  background-color: #fafafa;
  flex-grow: 1;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: #000000;
  background-image: url("/page-header-mobile.png");
  background-size: cover;
  min-height: 124px;
  padding-bottom: 2rem;
  width: 100%;
  @media (min-width: 1920px) {
    min-height: 150px;
    background-image: url("/page-header-widescreen.png");
    background-size: cover;
  }
  @media (min-width: 1024px) and (max-width: 1920px) {
    min-height: 150px;
    background-image: url("/page-header-desktop.png");
    background-size: cover;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    min-height: 150px;
    background-image: url("/page-header-tablet.png");
    background-size: cover;
  }
`

const Content = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 3rem;
`

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  font-family: "Open Sans";
  margin: 0;
  color: #ffffff;
  font-weight: bold;
  font-size: 28px;
  line-height: 116%;
  text-align: center;
  @media (min-width: 1024px) {
    font-size: 36px;
  }
  @media (min-width: 736px) and (max-width: 1024px) {
    font-size: 36px;
  }
`

const ChildrenWrapper = styled.div`
  flex-grow: 1;
`

type Props = {
  title: string
  children: React.ReactNode
}

const PageLayout = ({ title, children }: Props) => (
  <Container>
    <Content>
      <Hero>
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
      </Hero>

      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Content>
  </Container>
)

export default PageLayout
