/*
Divisas: Todas - Spot - Futuros
Acciones: Todas - Estados Unidos - Europa - Canadá - Asia
ADRs: Todos - Chile - Perú - Argentina - Colombia - Brasil (los que  en US los dejaría en Chile)
Commodities: Todos - Spot - Futuros
Índices: Todos - Spot - Futuros
ETFs: Todos (cuando tengamos despues las categorías las agregamos, mientras no pongamos nada)
*/

type NameCategory = {
  [key: string]: string
}

const nameCategory: NameCategory = {
  spot: "Spot",
  forward: "Futuros",
  us: "Estados Unidos",
  europa: "Europa",
  canada: "Canadá",
  asia: "Asia",
  argentina: "Argentina",
  chile: "Chile",
  peru: "Perú",
  colombia: "Colombia",
  brasil: "Brasil",
}

export const transformNameCategory = (text: string) => {
  if (nameCategory[text.toLocaleLowerCase()]) {
    return nameCategory[text.toLocaleLowerCase()]
  }

  return `${text.slice(0, 1).toLocaleUpperCase()}${text.slice(1)}`
}
