import React, { useState } from "react"
import styled from "@emotion/styled"

import { Modal, Paragraph } from "./modal"
import QuestionIcon from "../../ui/images/question"

const TitleRatioAdr = styled.div`
  line-height: 15px;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 74px;
  @media (max-width: 1024px) {
    max-width: 70px;
  }
`

const RatioAdrContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    border-bottom: 1px solid #efefef;
    justify-content: space-between;
    padding: 10px 0;
    > div {
      margin-left: 5px;
    }
  }
`

const ValueRatioAdr = styled.div`
  line-height: 16px;
`

type Props = {
  instrument: Instrument
  Divisor: any
}

const RatioAdr = ({ instrument, Divisor }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const closeModal = () => {
    setIsModalOpen(false)
  }
  const openModal = () => {
    setIsModalOpen(true)
  }
  return (
    <>
      {instrument.ratio_adr && (
        <>
          <Divisor></Divisor>
          <RatioAdrContainer>
            <TitleRatioAdr onClick={openModal}>
              Ratio ADR <QuestionIcon />
            </TitleRatioAdr>
            <ValueRatioAdr>{instrument.ratio_adr}</ValueRatioAdr>
          </RatioAdrContainer>
          <Modal
            title="Acerca del Ratio de ADR"
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          >
            <Paragraph>
              Este ratio busca fijar el precio de la Acción en nivel adecuado
              para la negociación en los Estados Unidos, lo fija el banco emisor
              del ADRs, no existiendo ninguna fórmula para su establecimiento,
              por lo que el precio del ADR viene dado por el resultado de oferta
              y demanda, tal como ocurre con cualquier otra acción. El ratio
              indica a cuántas acciones chilenas es equivalente en el mercado
              norteamericano 1 ADR. Se debe tener presente que cada lotes se
              compone de 50 ADRs, por lo cual el equivalente en número de
              acciones chilenas que está transando el inversionista (valorizadas
              al USDCLP del momento) resultará multiplicando el valor de tamaño
              del lote (50) por el valor del ratio. En la tabla superior, se
              menciona este ratio sólo de manera referencial y para dar una
              información más completa al cliente, sin embargo, el ratio no es
              manejado ni administrado por KT Financial Group, por lo cual
              podría sufrir modificaciones sin previo aviso.
            </Paragraph>
          </Modal>
        </>
      )}
    </>
  )
}
export default RatioAdr
