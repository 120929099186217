export const trackEvent = (event: string, properties?: any) => {
  if (typeof window !== "undefined" && window.amplitude) {
    window.amplitude.getInstance().logEvent(event, properties)
  }
}

export const trackUser = (properties: any) => {
  if (typeof window !== "undefined" && window.amplitude) {
    window.amplitude.getInstance().setUserProperties(properties)
  }
}

export const trackUserEvent = (
  event: string,
  userProperties: any,
  eventProperties?: any
) => {
  trackUser(userProperties)
  trackEvent(event, eventProperties)
}
