import React from "react"
import styled from "@emotion/styled"

export const BaseColumn = styled.div`
  max-width: 100px;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  @media (max-width: 1024px) {
    display: none;
  }
`
export const NemoColumn = styled(BaseColumn)`
  cursor: pointer;
  @media (max-width: 1024px) {
    display: flex;
    justify-content: left;
  }
`
export const VolumenColumn = styled(BaseColumn)`
  cursor: pointer;
`
export const MinMaxVolColumn = styled(BaseColumn)``
export const PipColumn = styled(BaseColumn)`
  cursor: pointer;
`
export const MarginColumn = styled(BaseColumn)`
  cursor: pointer;
`
export const SpreadColumn = styled(BaseColumn)``
export const SwapColumn = styled(BaseColumn)``

export const NameColumn = styled(BaseColumn)`
  max-width: 210px;
  cursor: pointer;
  @media (max-width: 1024px) {
    display: flex;
    justify-content: left;
    max-width: 350px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`
export const MarginCurrencyColumn = styled(BaseColumn)`
  max-width: 70px;
  cursor: pointer;
`
export const ActionColumn = styled(BaseColumn)`
  width: 28px;
  height: 28px;

  border: 2px solid transparent;

  &.open {
    border: 2px solid #72c552;
    border-radius: 5px;
  }

  @media (max-width: 1024px) {
    display: flex;
  }
`
export const definitionColumns = (typeAccount: string) => [
  {
    component: NemoColumn,
    text: "NEMO",
    sort: "nemo",
  },
  {
    component: NameColumn,
    text: "Instrumento",
    sort: "name",
  },
  {
    component: MarginCurrencyColumn,
    text: "Moneda Base",
    sort: "margin_currency",
  },
  {
    component: VolumenColumn,
    text: (
      <>
        Tamaño <br />1 Lote*
      </>
    ),
    sort: "volumen_in_currency",
  },
  {
    component: MinMaxVolColumn,
    text: (
      <>
        Monto operación <br />
        Mín/Máx
      </>
    ),
  },
  {
    component: PipColumn,
    text: (
      <>
        Valor PIP* <br /> en Lote
      </>
    ),
    sort: "pip_value",
  },
  {
    component: MarginColumn,
    text: "Margen",
    sort: "margin",
  },
  {
    component: SpreadColumn,
    text:
      typeAccount === "start" ? (
        "Spread* desde"
      ) : (
        <>
          Spread* desde
          <br />
          Pro / Premium
        </>
      ),
  },
  {
    component: SwapColumn,
    text: (
      <>
        Swap*
        <br />
        Compra / Venta
      </>
    ),
  },
  {
    component: ActionColumn,
    text: "",
  },
]
