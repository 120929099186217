import React from "react"

const PeruFlag = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.22222 2.77821C0.995 2.77821 0 3.77321 0 5.00043V15.0004C0 16.2277 0.995 17.2227 2.22222 17.2227H6.66667V2.77821H2.22222Z"
      fill="#D91023"
    />
    <path
      d="M6.66602 17.2227H13.3327V2.77821H6.66602V17.2227Z"
      fill="#EEEEEE"
    />
    <path
      d="M17.7784 2.77821H13.334V17.2227H17.7784C19.0057 17.2227 20.0007 16.2277 20.0007 15.0004V5.00043C20.0007 3.77321 19.0057 2.77821 17.7784 2.77821Z"
      fill="#D91023"
    />
  </svg>
)

export default PeruFlag
