import React from "react"

const ChileFlag = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipchile)">
      <path
        d="M4.63167 7.94195L3.58611 7.18251L2.54056 7.94195L2.94 6.71306L1.89444 5.95306H3.18667L3.58611 4.72418L3.98556 5.95306H5.27778L4.23222 6.71306L4.63167 7.94195ZM7.22222 2.77973H2.22222C0.995 2.77973 0 3.77473 0 5.00195V10.002H7.22222V2.77973Z"
        fill="#1F429B"
      />
      <path
        d="M17.7782 2.77973H7.22266V10.002H20.0004V5.00195C20.0004 3.77473 19.0054 2.77973 17.7782 2.77973Z"
        fill="#EEEEEE"
      />
      <path
        d="M0 10.0004V15.0004C0 16.2277 0.995 17.2227 2.22222 17.2227H17.7778C19.005 17.2227 20 16.2277 20 15.0004V10.0004H7.22222H0Z"
        fill="#D42D27"
      />
      <path
        d="M3.98369 5.95252L3.58424 4.72363L3.18536 5.95252H1.89258L2.93813 6.71196L2.53869 7.94141L3.58424 7.18141L4.6298 7.94141L4.23036 6.71196L5.27591 5.95252H3.98369Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clipchile">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ChileFlag
