export const normalizeInstrument = (instrument: any) => {
  if (
    typeof instrument === "object" &&
    instrument !== null &&
    instrument.text !== undefined
  ) {
    return instrument.text
  }

  return instrument
}

export const normalizeInstruments = (
  instruments: any,
  market: string,
  category: string
) => {
  if (!Array.isArray(instruments) || instruments.length === 0) return []

  return instruments[0].items.map((instrument: Instrument) => {
    const item: Instrument = Object.entries(instrument).reduce(
      (accumulator, [name, value]) => ({
        ...accumulator,
        [name]: normalizeInstrument(value),
      }),
      {}
    ) as Instrument

    return {
      ...item,
      market,
      category,
      option: {
        market,
        category,
        value: item.nemo,
        label: `${item.nemo} - ${item.name}`,
      },
    }
  })
}

export const normalizeCategories = (categories: any, market: any) => {
  let allInstruments: OptionSelect[] = []
  if (categories && categories.data) {
    const categoriesMarket = Object.entries(categories.data).reduce(
      (accumulator, [category, dataCategory]) => {
        const nameCategory: string = category.replace("body_", "")
        const instruments = normalizeInstruments(
          dataCategory,
          market,
          nameCategory
        )

        allInstruments = allInstruments.concat(
          instruments.map((instrument: Instrument) => instrument.option)
        )

        return {
          ...accumulator,
          [nameCategory]: {
            nameCategory,
            instruments,
          },
        }
      },
      {}
    )

    return {
      categories: categoriesMarket,
      allInstruments,
    }
  }
  return { categories: {}, allInstruments }
}

type ConfigMarket = {
  base: string
  merged: string[]
}

const unifyMarkets = (markets: any, configUnifyMarket: ConfigMarket[]) => {
  configUnifyMarket.forEach(config => {
    config.merged.forEach(stock => {
      if (markets[stock]) {
        let dataEeuuBodyMarket
        for (let body in markets[stock].data) {
          dataEeuuBodyMarket = markets[config.base].data[body]
          if (dataEeuuBodyMarket) {
            dataEeuuBodyMarket[0].items = [
              ...dataEeuuBodyMarket[0].items,
              ...markets[stock].data[body][0].items,
            ]
          } else {
            markets[config.base].data[body] = markets[stock].data[body]
          }
        }
        delete markets[stock]
      }
    })
  })


  return markets
}

export const normalizeMarket = (markets: any) => {
  const stockEEUUBase = "prismicStocksPro"
  const stocksToMerged = [
    "prismicStocksEeuuPro",
    "prismicStocksEuropaPro",
    "prismicStocksCanadaPro",
    "prismicStocksAsiaPro",
  ]

  const configUnifyMarket: ConfigMarket[] = [
    {
      base: "prismicStocksPro",
      merged: [
        "prismicStocksEeuuPro",
        "prismicStocksEuropaPro",
        "prismicStocksCanadaPro",
        "prismicStocksAsiaPro",
      ]
    },
    {
      base: "prismicForexPro",
      merged: [
        "prismicCryptoPro",
      ]
    },
    {
      base: "prismicForexStart",
      merged: [
        "prismicCryptoStart",
      ]
    }
  ]

  const listUnifyMarkets = unifyMarkets(markets, configUnifyMarket)

  const normalizeMarkets = Object.entries(listUnifyMarkets).map(
    ([market, infoMarket]) => {
      const nameMarket = market.replace(/Start|Pro|prismic/gi, "")
      const {
        allInstruments,
        categories,
      }: PropsNormalizeCategory = normalizeCategories(infoMarket, nameMarket)

      return {
        name: nameMarket,
        type: market.includes("Start") ? "start" : "pro",
        categories,
        allInstruments,
      }
    }
  )

  return normalizeMarkets
}

export const getMarkets = (markets: any, type: string) => {
  console.log(markets)
  const marketsNormalized = normalizeMarket(markets).filter(
    (market: any) => market.type === type
  )

  return marketsNormalized.reduce(
    (accumulator, market: any) => ({ ...accumulator, [market.name]: market }),
    {}
  )
}
