import React from "react"
import styled from "@emotion/styled"

const TitleExchange = styled.div`
  line-height: 16px;
  font-weight: bold;
  margin-right: 10px;
`

const ValueExchange = styled.div``

const ExchangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    margin-right: 0px;
  }
  @media (max-width: 1024px) {
    border-bottom: 1px solid #efefef;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
    > div {
      margin-left: 5px;
    }
  }
`

type Props = {
  instrument: Instrument
  Divisor: any
}
const Exchange = ({ instrument, Divisor }: Props) => (
  <>
    {instrument.exchange && (
      <>
        <Divisor></Divisor>
        <ExchangeContainer>
          <TitleExchange>Bolsa</TitleExchange>
          <ValueExchange>{instrument.exchange}</ValueExchange>
        </ExchangeContainer>
      </>
    )}
  </>
)

export default Exchange
