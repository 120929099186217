import React from "react"
import styled from "@emotion/styled"

const X = styled.img`
  width: 14px;
  height: 14px;
`

const XIcon = () => <X src="/x.png" alt="skip" />

export default XIcon
