import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import Select, { components } from "react-select"

import SearchIcon from "../ui/images/search-icon"

const SearchDiv = styled.div`
  margin: 20px;
  margin-bottom: 70px;
`

const countriesStyles = {
  control: (provided: any) => ({
    ...provided,
    width: 361,
    height: 50,
    border: "0px",
    outline: "none",
    boxShadow: "none",
    "@media (max-width: 1024px)": {
      height: 58,
    },
    "@media (min-width: 450px)": {
      width: "440px",
    },
    ":active": {
      outline: "none",
    },
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    background: "linear-gradient(180deg, #30AB76 0%, #71C552 100%)",
    width: "68px",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    "@media (max-width: 1024px)": {
      height: "58px",
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    border: "1px solid #E6E6E6",
    height: "100%",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2,
  }),
}

const SearchComponent = ({ setSearch, options }: SearchProps) => {
  return (
    <SearchDiv>
      <Select
        onChange={setSearch}
        components={{
          DropdownIndicator: (props: any) => (
            <components.DropdownIndicator {...props}>
              <SearchIcon />
            </components.DropdownIndicator>
          ),
        }}
        options={options}
        placeholder="Buscar instrumento"
        styles={countriesStyles}
        value={null}
      />
    </SearchDiv>
  )
}

export default SearchComponent
