import React from "react"

const GreenArrowIcon = () => (
  <svg
    width="24"
    height="15"
    viewBox="0 0 24 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.83 -9.2537e-07L12 9.17L21.17 -1.23703e-07L24 2.83L12 14.83L-1.23703e-07 2.83L2.83 -9.2537e-07Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="24"
        y1="7.415"
        x2="-3.2412e-07"
        y2="7.415"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
    </defs>
  </svg>
)

export default GreenArrowIcon
