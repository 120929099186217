import React, { useState } from "react"
import styled from "@emotion/styled"
import { Modal, Paragraph } from "./modal"
import QuestionIcon from "../../ui/images/question"
import { formatDate } from "../../../helpers/format-date"

const TitleContainer = styled.div`
  line-height: 15px;
  font-weight: bold;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 74px;
  @media (max-width: 1024px) {
    max-width: 70px;
  }
`

const DividendRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    border-bottom: 1px solid #efefef;
    flex-direction: column;
    align-items: initial;
    padding: 10px 0 10px 5px;
  }
`

const TitleDividend = styled.div`
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 5px;
  @media (max-width: 1024px) {
    font-weight: bold;
  }
`

const InfoDividend = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

const DataDividend = styled.div`
  text-align: center;
  margin: 0 10px;
  @media (max-width: 1024px) {
    text-align: initial;
    margin: 0;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }
`

const ValueDividend = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  @media (max-width: 1024px) {
    font-weight: initial;
  }
`

const BreakLineDesktop = styled.br`
  @media (max-width: 1024px) {
    display: none;
  }
`

type Props = {
  instrument: Instrument
  Divisor: any
}

const Dividend = ({
  instrument: {
    dividend_date,
    dividend_pay_for_contract,
    dividend_charge_for_contract,
  },
  Divisor,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const dividendDate = formatDate(dividend_date)

  return (
    <>
      {dividendDate &&
        dividend_pay_for_contract &&
        dividend_charge_for_contract && (
          <>
            <Divisor />
            <DividendRow>
              <TitleContainer onClick={openModal}>
                Dividendo
                <QuestionIcon />
              </TitleContainer>
              <InfoDividend>
                <DataDividend>
                  <TitleDividend>
                    Fecha
                    <BreakLineDesktop /> de Pago
                  </TitleDividend>
                  <ValueDividend>{dividendDate}</ValueDividend>
                </DataDividend>
                <DataDividend>
                  <TitleDividend>
                    Pago por
                    <BreakLineDesktop /> contrato
                  </TitleDividend>
                  <ValueDividend>{dividend_pay_for_contract}</ValueDividend>
                </DataDividend>
                <DataDividend>
                  <TitleDividend>
                    Cobro por
                    <BreakLineDesktop /> contrato
                  </TitleDividend>
                  <ValueDividend>{dividend_charge_for_contract}</ValueDividend>
                </DataDividend>
              </InfoDividend>
            </DividendRow>
            <Modal
              title="Acerca del dividendo"
              isModalOpen={isModalOpen}
              closeModal={closeModal}
            >
              <Paragraph>
                Cada vez que una acción específica pague dividendos en el
                mercado subyacente, el CFD ofrecido por el broker que replica el
                movimiento de dicha acción estará afecto a un ajuste que puede
                ser positivo o negativo, dependiendo de la posición que tenga el
                cliente. Este ajuste, se verá reflejado en la cuenta del cliente
                (directamente en el balance), antes de la apertura de la fecha
                “ex – date” (fecha de corte) del activo subyacente.
              </Paragraph>
              <Paragraph>
                Quien mantenga una posición larga en acciones recibirá el 70%
                del dividendo total pagado por acción, mientras que las
                posiciones cortas deben pagar el 100% del dividendo anunciado,
                de acuerdo con las condiciones conseguidas por KT en los
                mercados internacionales. Pese a lo anterior, se debe tener
                presente que este pago no corresponde a un dividendo, sino que
                sólo es un ajuste de dinero que intenta replicar lo que ocurre
                en el mercado subyacente. Por lo mismo, el 30% del dividendo no
                percibido, en ningún caso representa un crédito impositivo para
                el cliente.
              </Paragraph>
              <Paragraph>
                Un lote de CFD sobre acciones y ADRs corresponden a 50 acciones
                del mercado subyacente, por lo tanto, el dividendo recibido o
                pagado por el cliente será el equivalente al percibido por 50
                acciones en el mercado subyacente.
              </Paragraph>
              <Paragraph>
                El pago/cobro de dividendo se hará junto con el proceso de cobro
                del coste de financiamiento. Este cargo o abono no se observa en
                la posición abierta sino en el historial de la cuenta.
              </Paragraph>
              <Paragraph>
                Las fechas y los montos mencionados en la tabla superior son
                referenciales, y pueden ir variando sin previo aviso. Es
                responsabilidad del cliente instruirse acerca de las condiciones
                de cada acción de acuerdo a la información formal disponible en
                el mercado.
              </Paragraph>
            </Modal>
          </>
        )}
    </>
  )
}

export default Dividend
