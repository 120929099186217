import React from "react"

const Action = () => (
  <svg
    width="6"
    height="22"
    viewBox="0 0 6 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.00065 5.66732C4.47398 5.66732 5.66732 4.47398 5.66732 3.00065C5.66732 1.52732 4.47398 0.333984 3.00065 0.333984C1.52732 0.333984 0.333984 1.52732 0.333984 3.00065C0.333984 4.47398 1.52732 5.66732 3.00065 5.66732ZM3.00065 8.33398C1.52732 8.33398 0.333984 9.52732 0.333984 11.0007C0.333984 12.474 1.52732 13.6673 3.00065 13.6673C4.47398 13.6673 5.66732 12.474 5.66732 11.0007C5.66732 9.52732 4.47398 8.33398 3.00065 8.33398ZM3.00065 16.334C1.52732 16.334 0.333984 17.5273 0.333984 19.0007C0.333984 20.474 1.52732 21.6673 3.00065 21.6673C4.47398 21.6673 5.66732 20.474 5.66732 19.0007C5.66732 17.5273 4.47398 16.334 3.00065 16.334Z"
      fill="#4B4B4B"
    />
  </svg>
)

export default Action
