import React from "react"

const ActionOpen = () => (
  <svg
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4C3.105 4 4 3.105 4 2C4 0.895 3.105 0 2 0C0.895 0 0 0.895 0 2C0 3.105 0.895 4 2 4ZM2 6C0.895 6 0 6.895 0 8C0 9.105 0.895 10 2 10C3.105 10 4 9.105 4 8C4 6.895 3.105 6 2 6ZM2 12C0.895 12 0 12.895 0 14C0 15.105 0.895 16 2 16C3.105 16 4 15.105 4 14C4 12.895 3.105 12 2 12Z"
      fill="url(#paint0_action_open)"
    />
    <defs>
      <linearGradient
        id="paint0_action_open"
        x1="2"
        y1="0"
        x2="2"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#30AB76" />
        <stop offset="1" stopColor="#71C552" />
      </linearGradient>
    </defs>
  </svg>
)

export default ActionOpen
