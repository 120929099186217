import React from "react"
import styled from "@emotion/styled"

const Cell = styled.div`
  display: none;
  justify-content: space-between;
  min-height: 40px;
  border-bottom: 1px solid #efefef;
  align-items: center;
  padding-left: 5px;
  @media (max-width: 1024px) {
    display: flex;
  }
`

const TitleCell = styled.div`
  line-height: 16px;
  font-weight: bold;
  margin-right: 10px;
`

const ValueCell = styled.div``

const getSpread = (
  typeAccount: string,
  columnsTypeAccount: ColumnsTypes,
  instrument: Instrument
) => {
  if (typeAccount === "start") {
    return instrument[columnsTypeAccount["start"].spread]
  } else {
    const spreadPro = instrument[columnsTypeAccount["pro"].spread]
    const spreadPremium = instrument[columnsTypeAccount["premium"].spread]

    return `${spreadPro} / ${spreadPremium}`
  }
}

type Props = {
  instrument: Instrument
  columnsTypeAccount: ColumnsTypes
  typeAccount: string
}

const BaseInformation = ({
  instrument,
  columnsTypeAccount,
  typeAccount,
}: Props) => {
  const spreadLabel =
    typeAccount === "start" ? "Spread* desde" : "Spread* desde Pro / Premium"
  const spreadValue = getSpread(typeAccount, columnsTypeAccount, instrument)

  return (
    <>
      <Cell>
        <TitleCell>Nombre</TitleCell>
        <ValueCell>{instrument.name}</ValueCell>
      </Cell>
      <Cell>
        <TitleCell>Moneda Base</TitleCell>
        <ValueCell>{instrument.margin_currency}</ValueCell>
      </Cell>
      <Cell>
        <TitleCell>Tamaño 1 Lote*</TitleCell>
        <ValueCell>{instrument.volumen_in_currency}</ValueCell>
      </Cell>
      <Cell>
        <TitleCell>Monto operación Mín/Máx</TitleCell>
        <ValueCell>
          {instrument[columnsTypeAccount[typeAccount].min_volumen]} /{" "}
          {instrument[columnsTypeAccount[typeAccount].max_volumen]}
        </ValueCell>
      </Cell>
      <Cell>
        <TitleCell>Valor PIP* en Lote</TitleCell>
        <ValueCell>{instrument.pip_value}</ValueCell>
      </Cell>
      <Cell>
        <TitleCell>Margen</TitleCell>
        <ValueCell>{instrument.margin}</ValueCell>
      </Cell>
      <Cell>
        <TitleCell>{spreadLabel}</TitleCell>
        <ValueCell>{spreadValue}</ValueCell>
      </Cell>
      {instrument.buy_swap && instrument.sell_swap && (
        <Cell>
          <TitleCell>Swap* Compra / Venta</TitleCell>
          <ValueCell>
            {instrument.buy_swap} / {instrument.sell_swap}{" "}
          </ValueCell>
        </Cell>
      )}
    </>
  )
}

export default BaseInformation
